<template>
    <div class="mt-n6 div-register">
        <div class="pt-10 d-block">
            <label class="yellow--text text--darken-3 font-weight-bold text-h6 pb-2" style="border-bottom: 1px solid;">
                สมัครสมาชิก
            </label>
        </div>
        <v-container class="pt-15 pb-16" v-resize="onResize" :style="{width: windowSize.w + 'px'}">
            <v-form v-model="isValid" ref="personForm" v-if="form.type == 'person'" class="form-register">
                <!-- type -->
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2 pt-8">
                        ประเภทสมาชิก
                    </v-col>
                    <v-col cols="8">
                        <v-radio-group class="px-4" v-model="form.type" row>
                            <v-radio color="yellow darken-3" value="person" class="mr-1 mr-sm-10">
                                <template v-slot:label>
                                    <label class="grey--text text--lighten-2">บุคคลธรรมดา</label>
                                </template>
                            </v-radio>
                            <v-radio color="yellow darken-3" value="corporation">
                                <template v-slot:label>
                                    <label class="grey--text text--lighten-2">นิติบุคคล</label>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4"></v-col>
                    <v-col cols="8" class="yellow--text text--darken-3 text-left text-caption">
                        <label style="position: absolute;">กรุณากรอกข้อมูลด้านล่างให้ครบถ้วน</label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        ข้อมูลส่วนตัว
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="form.name" dense label="ชื่อ-นามสกุล" solo hint="ชื่อ-นามสกุล"
                            :rules="requiredRules"></v-text-field>
                        <div :class="{ 'd-block': windowSize.x < 600, 'd-flex': windowSize.x >= 600 }">
                            <v-text-field maxlength="10" v-model="form.mobile" :class="{ 'pr-3': windowSize.x >= 600 }"
                                dense label="เบอร์โทรศัพท์" solo :rules="mobileRules"></v-text-field>
                            <v-text-field v-model="form.email" dense label="อีเมล" type="email" solo
                                :rules="emailRules">
                            </v-text-field>
                        </div>
                        <v-text-field maxlength="13" v-model="form.idTax" dense
                            label="หมายเลขบัตรประชาชน / เลขที่ผู้เสียภาษี" solo
                            hint="หมายเลขบัตรประชาชน / เลขที่ผู้เสียภาษี" :rules="taxRules"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        ที่อยู่ในการติดต่อ
                    </v-col>
                    <v-col cols="8">
                        <v-textarea label="ค้นหาที่อยู่ของคุณ" solo rows="1" auto-grow v-model="addressText"
                            @click="$refs.dialogAddressForm.show(form.address)" :rules="addressRules"></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        เอกสารแนบ
                    </v-col>
                    <v-col cols="8">
                        <v-file-input v-model="form.fileId" class="mt-n3" dark color="yellow darken-3"
                            label="บัตรประชาชน" :rules="[v => !!v || 'โปรดแนบไฟล์']"></v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        รหัสผ่าน
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="form.password" dense label="รหัสผ่าน" solo :rules="passwordRules"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"></v-text-field>
                        <div class="text-left mt-n2 mb-2">
                            <label class="yellow--text text--darken-3 text-caption">
                                * รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร ประกอบด้วยตัวเลข ตัวอักษรพิมพ์เล็ก
                                ตัวอักษรพิมพ์ใหญ่ อักขระพิเศษ
                            </label>
                        </div>
                        <v-text-field v-model="form.confirmPassword" dense label="ยืนยันรหัสผ่าน" solo
                            :rules="[v => v == form.password || 'รหัสผ่านไม่ตรงกัน']"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"></v-text-field>
                    </v-col>
                </v-row>
                <v-btn rounded color="yellow darken-3" class="px-10 mt-4" @click="submitPerson">
                    สมัครสมาชิก
                </v-btn>
            </v-form>

            <!-- start corporation type -->
            <v-form v-model="isValid" ref="corporationForm" v-if="form.type == 'corporation'" class="form-register">
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2 pt-8">
                        ประเภทสมาชิก
                    </v-col>
                    <v-col cols="8">
                        <v-radio-group class="px-4" v-model="form.type" row>
                            <v-radio color="yellow darken-3" value="person" class="mr-sm-10 mr-1">
                                <template v-slot:label>
                                    <label class="grey--text text--lighten-2">บุคคลธรรมดา</label>
                                </template>
                            </v-radio>
                            <v-radio color="yellow darken-3" value="corporation">
                                <template v-slot:label>
                                    <label class="grey--text text--lighten-2">นิติบุคคล</label>
                                </template>
                            </v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4"></v-col>
                    <v-col cols="8" class="yellow--text text--darken-3 text-left text-caption">
                        <label style="position: absolute;">กรุณากรอกข้อมูลด้านล่างให้ครบถ้วน</label>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        ข้อมูลบริษัท
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="form.companyName" dense label="ชื่อบริษัท" solo :rules="requiredRules">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        ข้อมูลส่วนตัว
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="form.name" dense label="ชื่อ - นามสกุล" solo :rules="requiredRules">
                        </v-text-field>
                        <div class="d-flex">
                            <v-text-field maxlength="10" v-model="form.mobile" class="pr-3" dense label="เบอร์โทรศัพท์"
                                solo :rules="mobileRules"></v-text-field>
                            <v-text-field v-model="form.email" dense label="อีเมล" solo type="email"
                                :rules="emailRules">
                            </v-text-field>
                        </div>
                        <v-text-field maxlength="13" v-model="form.idTax" dense
                            label="หมายเลขบัตรประชาชน / เลขที่ผู้เสียภาษี" solo
                            hint="หมายเลขบัตรประชาชน / เลขที่ผู้เสียภาษี" :rules="taxRules"></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        ที่อยู่ในการติดต่อ
                    </v-col>
                    <v-col cols="8">
                        <v-textarea label="ค้นหาที่อยู่ของคุณ" solo rows="1" auto-grow v-model="addressText"
                            @click="$refs.dialogAddressForm.show(form.address)" :rules="addressRules"></v-textarea>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        เอกสารแนบ
                    </v-col>
                    <v-col cols="8">
                        <v-file-input v-model="form.fileKP" class="mt-n3" dark color="yellow darken-3"
                            placeholder="เอกสาร กพ. 20" :rules="[v => !!v || 'โปรดแนบไฟล์']"></v-file-input>
                        <v-file-input v-model="form.fileCert" class="mt-n3" dark color="yellow darken-3"
                            placeholder="หนังสือรับรอง" :rules="[v => !!v || 'โปรดแนบไฟล์']"></v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="grey--text text--lighten-2">
                        รหัสผ่าน
                    </v-col>
                    <v-col cols="8">
                        <v-text-field v-model="form.password" dense label="รหัสผ่าน" solo :rules="passwordRules"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"></v-text-field>
                        <div class="text-left mt-n2 mb-2">
                            <label class="yellow--text text--darken-3 text-caption">
                                * รหัสผ่านต้องมีความยาวอย่างน้อย 6 ตัวอักษร ประกอบด้วยตัวเลข ตัวอักษรพิมพ์เล็ก
                                ตัวอักษรพิมพ์ใหญ่ อักขระพิเศษ
                            </label>
                        </div>
                        <v-text-field v-model="form.confirmPassword" dense label="ยืนยันรหัสผ่าน" solo
                            :rules="[v => v == form.password || 'รหัสผ่านไม่ตรงกัน']"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="showPassword = !showPassword"></v-text-field>
                    </v-col>
                </v-row>
                <v-btn rounded color="yellow darken-3" class="px-10 mt-4" @click="submitCorp">
                    สมัครสมาชิก
                </v-btn>
            </v-form>
        </v-container>
        <dialog-address-form noRules @confirm="dialogAddressConfirm" ref="dialogAddressForm" />
        <dialog-error ref="dialogValidateFail" :topic="'ข้อมูลไม่ถูกต้อง'" :text="'กรุณากรอกข้อมูลใหม่อีกครั้ง'" />
        <dialog-error ref="dialogError" :topic="'Error'" :text="errorMessage" />
        <dialog-success ref="dialogSuccess" :text="'ลงทะเบียนสำเร็จแล้ว'" :redirectTo="{ name: 'Login' }" />
    </div>
</template>

<script>
import DialogAddressForm from '../components/DialogAddressForm.vue';
import { requiredRules, mobileRules, emailRules, passwordRules, taxRules } from '../utils/validation-rule';
import DialogError from '../components/DialogError.vue';
import DialogSuccess from '../components/DialogSuccess.vue';
import * as Customer from '../models/customer';

export default {
    data: () => ({
        height: 0,
        width: 0,
        initForm: {},
        isValid: false,
        form: {
            type: 'person',
            address: {
                id: 'address1Start',
                address1: '',
                address2: '',
                location: null,
                text: ''
            },
            companyName: '',
            name: '',
            mobile: '',
            email: '',
            fileId: null,
            fileKP: null,
            fileCert: null,
            password: '',
            confirmPassword: '',
            idTax: ''
        },
        addressText: '',
        windowSize: { x: 0, y: 0 },
        showPassword: false,
        passwordRules,
        addressRules: [
            v => v != null && v.length > 5 || 'โปรดกรอกที่อยู่' 
        ],
        requiredRules,
        mobileRules,
        emailRules,
        taxRules,
        errorMessage: ''
    }),
    methods: {
        onResize(){
            this.windowSize = { x: window.innerWidth, y: window.innerHeight };
        },
        dialogAddressConfirm(data){
            this.form.address = data;
            this.addressText = this.form.address.address2 + ' ' + this.form.address.address1;
            
        },
        async submitPerson(){
            this.$refs.personForm.validate();
            if(!this.isValid){
                this.$refs.dialogValidateFail.show();
            }else{
                let loader = this.$loading.show();
                try{
                    await Customer.registerPerson(this.form);
                    this.$refs.dialogSuccess.show();
                }catch(error){
                    this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                    this.$refs.dialogError.show();
                }finally{
                    loader.hide();
                }
            }
        },
        async submitCorp(){
            this.$refs.corporationForm.validate();
            if(!this.isValid){
                this.$refs.dialogValidateFail.show();
            }else{
                let loader = this.$loading.show();
                try{
                    await Customer.registerCorp(this.form);
                    this.$refs.dialogSuccess.show();
                }catch(error){
                    this.errorMessage = error.response ? Array.isArray(error.response.data) ? error.response.data[0].msg : error.response.data : error.message;
                    this.$refs.dialogError.show();
                }finally{
                    loader.hide();
                }
            }
        }
    },
    computed: {
    },
    mounted(){
        this.height = window.innerHeight - 64;
        this.width = window.innerWidth;
        this.$vuetify.goTo( 0, {
          duration: 0,
          offset: 0,
          easing: 'easeInOutCubic'
        });
        this.initForm = Object.assign({}, this.form);
    },
    watch: {
        ['form.type'](value){
            this.initForm.type = value;
            if('person' != value){
                this.$refs.personForm.resetValidation();
            }else{
                this.$refs.corporationForm.resetValidation();
            }
            this.form = Object.assign({}, this.initForm);
            console.log(value);
        }
    },
    components: {
        DialogAddressForm,
        DialogError,
        DialogSuccess
    }
};
</script>

<style scoped>
    .div-register{
        text-align: center;
    }
    .form-register{
        background-color: #0F0E0D;
        border-radius: 8px;
        padding-right: 32px;
        padding-bottom: 32px;
    }
    
</style>

<style>
    .v-radio .v-icon {
        background-image: radial-gradient(#cccccc 40%, transparent 10%);
    }
</style>